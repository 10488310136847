<template>
  <standard-modal name="deleteMkpAlertModal" max-width="500px">
    <template #title>
      <h3>{{ $t('mkpAlerts.modalDeletedTitle') }}</h3>
    </template>
    <template #content>
      <div>
        <div class="u-m-b-md" v-html="$t('mkpAlerts.modalDeletedMessage')" />
      </div>
    </template>
    <template #options>
      <div class="btn-wrapper">
        <main-button
          class-variant="btn-steel full-width"
          tag="a"
          :label="$t('actions.closeButton')"
          @click="closeModal"
        />
      </div>
    </template>
  </standard-modal>
</template>

<script>
import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'MkpAlertsConfirmModal',
  components: { StandardModal, MainButton },
  emits: ['modalClose_deleteMkpAlertModal'],
  methods: {
    closeModal() {
      this.$bus.emit('modalClose_deleteMkpAlertModal', 'deleteMkpAlertModal')
    },
  },
}
</script>
